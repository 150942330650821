import { Milestone } from "@/models/milestone.interface";
import milestoneService from "@/services/milestone.service";
import Vue from "vue";
import { MilestoneState } from "./milestone.state";

export default {
  namespaced: true,
  state: {
    milestones: {
      milestones: [],
    },
  },
  getters: {
    getMilestones: (state: MilestoneState) => state.milestones,
  },
  mutations: {
    setMilestones(state: any, milestones: any) {
      Vue.set(state, "milestones", milestones);
    },
    setMilestonesCombined(state: any, milestones: any) {
      milestones.milestones.unshift(state.milestones);
      Vue.set(state, "milestones", milestones);
    },
  },
  actions: {
    getMilestones: async (
      context: any,
      payload: {
        page: string;
        size: number;
        bill_id: string;
        combine: boolean;
      }
    ) => {
      try {
        const milestones = (await milestoneService.getMilestones(payload)).data;
        context.commit(
          payload.combine ? "setMilestonesCombined" : "setMilestones",
          milestones
        );
        return milestones;
      } catch (error) {
        throw new Error(error);
      }
    },
    createMilestone: async (
      context: any,
      payload: { product?: string; bill?: string; milestone: Milestone }
    ) => {
      try {
        await milestoneService.createMilestone(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
