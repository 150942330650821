
















import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({})
export default class ConcurrencyValidation extends Vue {
  show = false;

  private get showValidation(): boolean {
    return this.$store.getters["notifications/getShowConcurrencyValidation"];
  }

  @Watch("showValidation")
  private avoidCloseError(): void {
    this.show = this.showValidation;
  }
}
