import { Privilege } from "@/models/privilege.interface";
import Vue from "vue";
import store from "@/store";
type permsObject = {
  actions: "C" | "R" | "U" | "D" | string[];
  entities: string[];
};
export type PermissionsCheck = string[] | permsObject;

const mapAction = (action: string) => action.charAt(0).toUpperCase();

export function checkPerms(permissions: PermissionsCheck) {
  const user_privileges: Privilege[] =
    store.getters["authentication/getPrivileges"];
  const privileges: string[] = Array.isArray(permissions)
    ? permissions
    : permissions.entities;
  const types = !Array.isArray(permissions)
    ? Array.isArray(permissions.actions)
      ? permissions.actions.map(mapAction)
      : mapAction(permissions.actions)
    : ["R"];
  let has_permission = false;
  user_privileges.forEach((user_privilege) => {
    privileges.forEach((privilege) => {
      if (
        Vue.prototype.$constants.PRIVILEGES[privilege] ==
          Vue.prototype.$constants.PRIVILEGES.NONE ||
        (user_privilege.name ==
          Vue.prototype.$constants.PRIVILEGES[privilege] &&
          types.includes(user_privilege.type))
      ) {
        has_permission = true;
      }
    });
  });
  return has_permission;
}
