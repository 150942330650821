import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  createBill(payload: { id: string; quantity: number }) {
    return API_URL.post(
      `/bills`,
      {
        product: {
          id: payload.id,
          quantity: payload.quantity,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getSingleBill(bill_id: string) {
    return API_URL.get(`/bills/${bill_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getBillForChat(bill_id: string) {
    return API_URL.get(`/bills/for-chat/${bill_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getUserBills(payload: {
    page: string;
    size: number;
    user_id: string;
    operation: string;
    bill_id: string;
  }) {
    return API_URL.get(
      `/bills?client=${payload.user_id}&size=${payload.size}&page=${payload.page}&operation=${payload.operation}&bill_id=${payload.bill_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getBills(payload: {
    page: string;
    size: number;
    operation: string;
    bill_id?: string;
    status?: string;
    payment_status?: string;
    end_date?: string;
    manual_returns?: boolean;
  }) {
    return API_URL.get(`/bills`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
      params: {
        size: payload.size,
        page: payload.page,
        operation: payload.operation,
        ...(payload.bill_id && { bill_id: payload.bill_id }),
        ...(payload.status && { status: payload.status }),
        ...(payload.payment_status && {
          payment_status: payload.payment_status,
        }),
        ...(payload.end_date && { end_date: payload.end_date }),
        ...(payload.manual_returns && { manual_returns: "true" }),
      },
    });
  },
  updateBillStatus(payload: { id: string; status: string }) {
    return API_URL.put(
      `/bills/status/${payload.id}`,
      {
        status: payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
