








































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import Header from "@/components/Layout/Client/Header.vue";
import SimpleHeader from "@/components/Layout/Client/SimpleHeader.vue";
import SideBar from "@/components/Layout/Client/SideBar.vue";
import AdministratorSideBar from "@/components/Layout/Backoffice/AdministratorSideBar.vue";
import AdministratorHeader from "@/components/Layout/Backoffice/AdministratorHeader.vue";
import FAB from "@/components/Layout/Backoffice/FAB.vue";
import Footer from "@/components/Layout/Client/Footer.vue";
import BottomNavigation from "@/components/Layout/Client/BottomNavigation.vue";
import SystemBar from "@/components/Layout/Client/SystemBar.vue";
import GlobalLoader from "@/components/General/GlobalLoader.vue";
import SystemError from "@/components/General/SystemError.vue";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import Notification from "@/components/General/Notification.vue";
import ConcurrencyNotification from "@/components/General/ConcurrencyNotification.vue";
import ConcurrencyValidation from "./components/General/ConcurrencyValidation.vue";
import { SystemError as SysError } from "@/models/system-error.interface";
import { Watch } from "vue-property-decorator";
import ConnectionStatus from "@/components/General/ConnectionStatus.vue";
import ChatNotification from "./components/General/ChatNotification.vue";
import { UserValidations } from "@/mixins/user-validations";
import { DeviceCheck } from "@/mixins/device-check";
import { Socket } from "vue-socket.io-extended";
import Cookies from "js-cookie";

@Component({
  components: {
    Header,
    SimpleHeader,
    Footer,
    BottomNavigation,
    SideBar,
    SystemBar,
    AdministratorSideBar,
    AdministratorHeader,
    GlobalLoader,
    SystemError,
    LocaleSwitcher,
    Notification,
    FAB,
    ConnectionStatus,
    ChatNotification,
    ConcurrencyNotification,
    ConcurrencyValidation,
  },
})
export default class App extends mixins(
  Navigation,
  CustomDate,
  UserValidations,
  DeviceCheck
) {
  switch = false;
  administratorSwitch = false;
  loader = true;

  @Socket("penalty:update")
  private async penaltyUpdateHandler(payload: any) {
    if (payload.user_id == this.userInfo.id && payload.to_profile) {
      this.updateUserInfo();
      this.navigate("/profile");
    }
  }

  @Socket("session:expired")
  private async sessionExpiredHandler(payload: any) {
    if (payload.user_id == this.userInfo.id) {
      await this.$store.dispatch("users/getUserSessions").catch(async () => {
        await this.$store.dispatch("authentication/logOut").then(() => {
          this.navigate("/home");
        });
      });
    }
  }

  @Socket("functionality:updated")
  private async functionalityUpdatedHandler() {
    await this.getSystemData();
  }

  private get userInfo(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user;
    } else {
      return "";
    }
  }

  private openSideBar(): void {
    this.switch = !this.switch;
  }
  private get showFAB() {
    return !this.isMobile() && this.hasContextBackoffice && !this.isCheckout;
  }
  private openAdministratorSideBar(): void {
    this.administratorSwitch = !this.administratorSwitch;
  }

  private get drawer(): boolean {
    return this.switch;
  }

  private get administratorDrawer(): boolean {
    return this.administratorSwitch;
  }

  private get loading(): boolean {
    return this.loader;
  }

  private setBaseData(response: any) {
    this.$store.dispatch(
      "functionalities/setFunctionalitiesByInitialData",
      response.functionalities
    );
    this.$store.dispatch("styles/setStylesByInitialData", response.styles);
    this.$store.dispatch(
      "settings/getFirstStepsRegisteredByInitialData",
      response.first_steps_check
    );

    if (!this.firstStepsRegistered) {
      this.navigate("/first-steps");
    } else {
      this.$store.dispatch(
        "system/getSystemInfoByInitialData",
        response.company_info
      );
    }
  }

  private setBaseDataForUser(response: any) {
    this.$store.dispatch(
      "functionalities/setFunctionalitiesByInitialData",
      response.functionalities
    );
    this.$store.dispatch(
      "authentication/updateUserInfoByInitialData",
      response.user_profile
    );
    this.$store.dispatch(
      "authentication/checkIfUserIsAvailableByInitiaData",
      response.user_is_available
    );
  }

  private async created(): Promise<void> {
    if (this.userLogged !== null) {
      await this.$store
        .dispatch("system/getInitialDataForUsers", this.userLogged.id)
        .then((response) => {
          if (response.login !== undefined && response.login) {
            this.$store.dispatch("authentication/logOut");
            location.reload();
          } else {
            this.setBaseData(response);
            this.setBaseDataForUser(response);
          }
        })
        .catch((error) => {
          const Error: SysError = {
            date: this.currentDate(),
            path: this.$route.fullPath,
            errorTraceback: error.stack,
            errorMessage: error.message,
            criticalError: true,
            description: this.$tc("Errors.App.firstStepsError"),
          };
          this.$store.dispatch("notifications/setShowNotification", false);
          this.$store.dispatch("notifications/showSystemError", Error);
        });
    } else {
      await this.$store
        .dispatch("system/getInitialDataForEveryone")
        .then(async (response) => {
          this.setBaseData(response);
          if (this.credentialForSessionExists)
            await this.$store.dispatch("authentication/logOut");
        })
        .catch((error) => {
          const Error: SysError = {
            date: this.currentDate(),
            path: this.$route.fullPath,
            errorTraceback: error.stack,
            errorMessage: error.message,
            criticalError: true,
            description: this.$tc("Errors.App.firstStepsError"),
          };
          this.$store.dispatch("notifications/setShowNotification", false);
          this.$store.dispatch("notifications/showSystemError", Error);
        });
    }

    this.loader = false;
  }

  private get credentialForSessionExists(): boolean {
    const routeName = this.$route.name;
    const sessionName = this.$store.getters["settings/getSessionName"];
    if (
      Cookies.get(sessionName) !== undefined &&
      routeName !== "ProjectApplication"
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get userRole() {
    return this.$store.getters["authentication/getRole"];
  }

  @Socket("role:status-update")
  private async roleStatusUpdateHandler(payload) {
    if (payload.login && this.userRole == payload.name) {
      this.$store.dispatch("authentication/logOut");
      this.navigate("/sign-in");
    }
  }

  @Socket("role:update")
  private async roleUpdateHandler(payload) {
    if (payload.name == this.userRole) {
      await this.updateUserInfo();
    }
  }

  @Socket("user:status-update")
  private async userStatusUpdateHandler(payload) {
    if (payload.login && this.userInfo.id == payload.user_id) {
      this.$store.dispatch("authentication/logOut");
      this.navigate("/sign-in");
    }
  }

  @Socket("user:role-update")
  private async userRoleUpdateHandler(payload) {
    if (payload.login && this.userInfo.id == payload.user_id) {
      this.$store.dispatch("authentication/logOut");
      this.navigate("/sign-in");
    }
  }

  private async updateUserInfo() {
    if (this.userLogged !== null) {
      await this.$store.dispatch("authentication/updateUserInfo", {
        id: this.userLogged.id,
      });
      this.assertMobileContext(true);
    }
  }

  private async checkUserAvailability(toLogin = false) {
    if (this.userInfo.id) {
      if (toLogin) {
        this.$store.dispatch("authentication/logOut");
        this.navigate("/sign-in");
      } else {
        if (!this.userIsAvailable) {
          this.$store.dispatch("authentication/logOut");
          this.navigate("/sign-in");
        }
      }
    }
  }

  private get userIsAvailable() {
    return this.$store.getters["authentication/getUserAvailable"];
  }

  private async getSystemData() {
    await this.$store.dispatch("styles/getStyles").catch((error) => {
      const Error: SysError = {
        date: this.currentDate(),
        path: this.$route.fullPath,
        errorTraceback: error.stack,
        errorMessage: error.message,
        criticalError: true,
        description: this.$tc("Errors.App.stylesError"),
      };
      this.$store.dispatch("notifications/setShowNotification", false);
      this.$store.dispatch("notifications/showSystemError", Error);
    });

    await this.$store
      .dispatch("functionalities/getFunctionalities")
      .catch((error) => {
        const Error: SysError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: true,
          description: this.$tc("Errors.App.functionalitiesError"),
        };
        this.$store.dispatch("notifications/setShowNotification", false);
        this.$store.dispatch("notifications/showSystemError", Error);
      });
  }

  private get functionalities(): any[] {
    return this.$store.getters["functionalities/getFunctionalities"];
  }

  private get colors(): any[] {
    return this.$store.getters["styles/getColors"];
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  private get hasContextBackoffice(): boolean {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.contexts.some((value: any) => {
        return value.name == this.$constants.CONTEXT.BACKOFFICE;
      });
    } else {
      return false;
    }
  }

  @Watch("functionalities")
  private async setMode() {
    this.functionalities.forEach((functionality) => {
      if (
        functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE &&
        functionality.status.name == this.$constants.STATUS.ACTIVE
      ) {
        this.$vuetify.theme.dark = true;
        this.$store.dispatch("styles/setTheme", true);
      } else if (
        functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE &&
        functionality.status.name == this.$constants.STATUS.INACTIVE
      ) {
        this.$vuetify.theme.dark = false;
        this.$store.dispatch("styles/setTheme", false);
      }
    });
  }

  @Watch("colors")
  private async setColors() {
    this.colors.forEach((color) => {
      if (
        color.mode == "LIGHT" &&
        (color.level == "PRIMARY" || color.level == "SECONDARY")
      ) {
        this.$vuetify.theme.themes.light[color.level.toLowerCase()] =
          color.value;
      }
    });
  }

  private get firstStepsRegistered(): boolean {
    return this.$store.getters["settings/getFirstStepsRegistered"];
  }

  private get isCheckout() {
    if (this.$route.name == "Checkout") {
      return true;
    } else {
      return false;
    }
  }
}
