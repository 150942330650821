import { User } from "@/models/user.interface";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export class UserValidations extends Vue {
  public get userLogged(): User {
    return this.$store.getters["authentication/getUser"];
  }

  public get userLoggedForConcurrency(): any {
    const user = this.$store.getters["authentication/getUser"];
    return {
      id: user.id,
      contact: `${user.first_name} ${user.last_name}, ${user.alias}, ${user.email}`,
    };
  }
}
