import Vue from "vue";
import { FunctionalitiesState } from "@/store/modules/Functionalities/functionalities.state";
import FunctionalitiesService from "@/services/functionalities.service";

export default {
  namespaced: true,
  state: {
    functionalities: [],
  },
  getters: {
    getFunctionalities: (state: FunctionalitiesState) => state.functionalities,
  },
  mutations: {
    setFunctionalities(state: FunctionalitiesState, functionalities: any[]) {
      Vue.set(state, "functionalities", functionalities);
    },
  },
  actions: {
    getFunctionalities: async (context: any) => {
      try {
        const response = (await FunctionalitiesService.getFunctionalities())
          .data;
        context.commit("setFunctionalities", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    setFunctionalityStatus: async (
      context: any,
      payload: { status: string; name: string; id: number }
    ) => {
      try {
        await FunctionalitiesService.updateFunctionalityStatus(
          { status: payload.status },
          payload.id
        );
        const functionalities = [...context.getters.getFunctionalities];
        functionalities.forEach((functionality) => {
          if (functionality.name == payload.name) {
            functionality.status.name = payload.status;
          }
        });
        await context.commit("setFunctionalities", [...functionalities]);
      } catch (error) {
        throw new Error(error);
      }
    },
    setFunctionalitiesByInitialData: async (context: any, payload: any) => {
      try {
        context.commit("setFunctionalities", payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
