
































import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Navigation } from "@/mixins/navigation";

@Component({})
export default class ConcurrencyNotification extends mixins(Navigation) {
  show = false;

  private get showNotification(): boolean {
    return this.$store.getters["notifications/getShowConcurrencyNotification"];
  }

  private get concurrencyInfo() {
    return this.$store.getters["notifications/getConcurrencyInfo"];
  }

  private closeNotification(): void {
    this.$store.dispatch("notifications/setShowConcurrencyNotification", false);
    if (this.concurrencyInfo.prev) {
      this.navigate(this.concurrencyInfo.prev);
    }
  }

  @Watch("showNotification")
  private avoidCloseError(): void {
    this.show = this.showNotification;
  }
}
