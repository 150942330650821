import Vue from "vue";
import { NotificationsState } from "@/store/modules/Notifications/notifications.state";
import { SystemError } from "@/models/system-error.interface";
import { Notification } from "@/models/notification.interface";

export default {
  namespaced: true,
  state: {
    error: {},
    notification: {},
    showError: false,
    showNotification: false,
    showConcurrencyNotification: false,
    concurrencyInfo: {},
    concurrencyValidation: false,
    chatOpened: false,
  },
  getters: {
    getSystemError: (state: NotificationsState) => state.error,
    getShowError: (state: NotificationsState) => state.showError,
    getNotification: (state: NotificationsState) => state.notification,
    getShowNotification: (state: NotificationsState) => state.showNotification,
    getShowConcurrencyValidation: (state: NotificationsState) =>
      state.concurrencyValidation,
    getShowConcurrencyNotification: (state: NotificationsState) =>
      state.showConcurrencyNotification,
    getConcurrencyInfo: (state: NotificationsState) => state.concurrencyInfo,
    getChatOpened: (state: NotificationsState) => state.chatOpened,
  },
  mutations: {
    setSystemError(state: NotificationsState, error: SystemError) {
      Vue.set(state, "error", error);
    },
    setShowError(state: NotificationsState, showError: boolean) {
      Vue.set(state, "showError", showError);
    },
    setNotification(state: NotificationsState, notification: Notification) {
      Vue.set(state, "notification", notification);
    },
    setShowNotification(state: NotificationsState, showNotification: boolean) {
      Vue.set(state, "showNotification", showNotification);
    },
    setConcurrencyInfo(state: NotificationsState, concurrencyInfo: any) {
      Vue.set(state, "concurrencyInfo", concurrencyInfo);
    },
    setShowConcurrencyValidation(
      state: NotificationsState,
      concurrencyValidation: boolean
    ) {
      Vue.set(state, "concurrencyValidation", concurrencyValidation);
    },
    setShowConcurrencyNotification(
      state: NotificationsState,
      showConcurrencyNotification: boolean
    ) {
      Vue.set(
        state,
        "showConcurrencyNotification",
        showConcurrencyNotification
      );
    },
    setChatOpened(state: NotificationsState, chatOpened: boolean) {
      Vue.set(state, "chatOpened", chatOpened);
    },
  },
  actions: {
    showSystemError: async (context: any, payload: SystemError) => {
      context.commit("setSystemError", payload);
      context.commit("setShowError", true);
    },
    setShowError: async (context: any, payload: boolean) => {
      context.commit("setShowError", payload);
    },
    showNotification: async (context: any, payload: Notification) => {
      context.commit("setNotification", payload);
      context.commit("setShowNotification", true);
    },
    setShowNotification: async (context: any, payload: boolean) => {
      context.commit("setShowNotification", payload);
    },
    setShowConcurrencyNotification: async (context: any, payload: boolean) => {
      context.commit("setShowConcurrencyNotification", payload);
    },
    setShowConcurrencyValidation: async (context: any, payload: boolean) => {
      context.commit("setShowConcurrencyValidation", payload);
    },
    setConcurrencyInfo: async (context: any, payload: any) => {
      context.commit("setConcurrencyInfo", payload);
    },
    setChatOpened: async (context: any, payload: boolean) => {
      context.commit("setChatOpened", payload);
    },
  },
};
