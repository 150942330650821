import store from "@/store/index";

const STATUS_TRANSLATE = {
  es: {
    ACTIVE: "ACTIVO",
    INACTIVE: "INACTIVO",
    DELETED: "ELIMINADO",
    CANCELED: "CANCELADO",
    RESERVED:
      "RESERVADO" /* User needs to be validated or waiting for multiple users */,
    TO_DO: "PENDIENTE" /* User needs to make the payment */,
    IN_PROCESS:
      "EN PROCESO" /* User made a payment but needs to complete with another OR is validating */,
    VALIDATING: "VALIDANDO" /* We need to confirm the payment */,
    WAITING_FOR_USERS:
      "ESPERANDO USUARIOS" /* Case of a product that needs 2+ users to buy a share */,
    FINISHED: "FINALIZADO",
    SENT: "ENVIADO",
    OPENED: "ABIERTO",
    REQUESTED: "SOLICITADO",
    PAID: "PAGADO",
    SUBMITTED: "REGISTRADO",
    ON_REVIEW: "EN REVISIÓN",
    CONTACTED: "CONTACTADO",
    ON_TIME: "A TIEMPO",
    DELAYED: "ATRASADO",
    IN_ADVANCE: "ADELANTADO",
  },
};

export function translateStatus(status = "UNKNOWN") {
  const currentLanguage = store.getters["language/getLanguage"];
  return (
    (currentLanguage &&
      STATUS_TRANSLATE[currentLanguage] &&
      STATUS_TRANSLATE[currentLanguage][
        status.replaceAll(/[ ,-]/g, "_").toUpperCase()
      ]) ||
    status.replaceAll("_", " ")
  );
}
