import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component({})
export class CustomDate extends Vue {
  reactiveDate = moment().format("DD-MM-YYYY hh:mm:ss A");

  currentDate() {
    return moment().format("DD-MM-YYYY hh:mm:ss A");
  }

  setReactiveCurrentDate() {
    this.reactiveDate = moment().format("DD-MM-YYYY hh:mm:ss A");
  }

  formatedDate(date: string, short = false) {
    const format = short ? "DD-MM-YYYY" : "DD-MM-YYYY hh:mm:ss A";
    return moment(date).format(format);
  }
}
