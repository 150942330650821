import { Milestone } from "@/models/milestone.interface";
import info from "@/utils/info";
import { API_URL } from "./service.config";

export default {
  createMilestone(payload: {
    product?: string;
    bill?: string;
    milestone: Milestone;
  }) {
    const config = {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    };
    return payload.product
      ? API_URL.post(
          `/products/${payload.product}/milestones`,
          payload.milestone,
          config
        )
      : API_URL.post(
          `/bills/${payload.bill}/milestones`,
          payload.milestone,
          config
        );
  },
  getMilestones(payload: { bill_id: string; page: string; size: number }) {
    return API_URL.get(`/bills/${payload.bill_id}/milestones`, {
      params: { page: payload.page, size: payload.size },
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
