import _Vue from "vue";

const Constants = {
  //  install
  install(Vue: typeof _Vue) {
    //  create global Constants
    Vue.prototype.$constants = {
      COLOR: {
        ACTION: "light-blue darken-3 white--text",
        DATATABLE: "black",
        LOADER: "blue-grey darken-1",
        ERROR: "red darken-1 white--text",
        SUCCESS: "green darken-1 white--text",
        WARNING: "amber darken-1",
        INFO: "blue darken-1",
        BACKGROUND: "grey darken-1 white--text",
        SIMPLE_NAVIGATION: "grey lighten-1",
        WT: "white--text",
        BT: "black--text",
      },
      STATUS: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
        DELETED: "DELETED",
        CANCELED: "CANCELED",
        RESERVED:
          "RESERVED" /* User needs to be validated or waiting for multiple users */,
        TO_DO: "TO-DO" /* User needs to make the payment */,
        IN_PROCESS:
          "IN PROCESS" /* User made a payment but needs to complete with another OR is validating */,
        FINISHED: "FINISHED",
        VALIDATING: "VALIDATING" /* We need to confirm the payment */,
        WAITING_FOR_USERS:
          "WAITING FOR USERS" /* Case of a product that needs 2+ users to buy a share */,
        SENT: "SENT",
        OPENED: "OPENED",
        REQUESTED: "REQUESTED",
        PAID: "PAID",
        SUBMITTED: "SUBMITTED",
        ON_REVIEW: "ON REVIEW",
        CONTACTED: "CONTACTED",
        PENALIZED: "PENALIZED",
        ON_TIME: "ON_TIME",
        DELAYED: "DELAYED",
        IN_ADVANCE: "IN_ADVANCE",
      },
      FUNCTIONALITY: {
        FIRST_STEPS: "FIRST STEPS",
        KYC: "KYC",
        DARK_MODE: "DARK MODE",
        ONLY_LOGO: "ONLY LOGO",
      },
      FILE_CATEGORY: {
        LOGO: "LOGO",
        CARROUSEL: "CARROUSEL",
        CATEGORY: "CATEGORY",
        DETAIL: "DETAIL",
        PRODUCT: "PRODUCT",
        PRODUCT_PREVIEW: "PRODUCT_PREVIEW",
        PRODUCT_DETAIL: "PRODUCT_DETAIL",
        PRODUCT_COMMENT: "PRODUCT_COMMENT",
        PAYMENT: "PAYMENT",
        BILL_COMMENT: "BILL_COMMENT",
        PLATFORM: "PLATFORM",
        KYC: "KYC",
      },
      OFFER: {
        INVESTMENT: "INVESTMENT",
        SALE: "SALE",
        AUCTION: "AUCTION",
      },
      PAYMENT_METHOD: {
        CRYPTOCURRENCY: {
          COINBASE: "COINBASE",
        },
        TRADITIONAL: {
          PAYPAL: "PAYPAL",
          TRANSFER: "TRANSFER",
        },
      },
      CONTEXT: {
        FRONTOFFICE: "FRONTOFFICE",
        BACKOFFICE: "BACKOFFICE",
      },
      USER_TYPE: {
        STAFF: "STAFF",
        OWNER: "OWNER",
        CLIENT: "CLIENT",
      },
      EMAILS_SUBJECT: {
        SET_NEW_PASSWORD: "Investment Management - Terminar Registro",
        CHANGE_PASSWORD: "Investment Management - Cambio de Contraseña",
        VALIDATE_USER: "Investment Management - Validar usuario",
      },
      EMAIL_TEMPLATES: {
        SET_NEW_PASSWORD: "set-new-password.hbs",
        CHANGE_PASSWORD: "change-password.hbs",
        NEW_CAMPAING: "newsletter-campaing.hbs",
        VALIDATE_USER: "validate-user.hbs",
      },
      PAGE: {
        TERMS_AND_CONDITIONS: "TERMS AND CONDITIONS",
        PRIVACY_POLICY: "PRIVACY POLICY",
        SALES_AND_PAYMENT_POLICIES: "SALES AND PAYMENT POLICIES",
        GENERAL_INFORMATION: "GENERAL INFORMATION",
        MESSAGE_OF_THE_DAY: "MESSAGE OF THE DAY",
      },
      PRIVILEGES: {
        ACCOUNT: "ACCOUNT",
        BALANCE: "BALANCE",
        BILL: "BILL",
        CATEGORY: "CATEGORY",
        "BILL COMMENT": "BILL COMMENT",
        "PRODUCT COMMENT": "PRODUCT COMMENT",
        COMPANY: "COMPANY",
        CONFIGURATION: "CONFIGURATION",
        DASHBOARD: "DASHBOARD",
        DETAIL: "DETAIL",
        FAQ: "FAQ",
        FEEDBACK: "FEEDBACK",
        CAROUSEL: "CAROUSEL",
        FUNCTIONALITY: "FUNCTIONALITY",
        KYC: "KYC",
        NEWSLETTER: "NEWSLETTER",
        PAYMENT: "PAYMENT",
        PLATFORM: "PLATFORM",
        PRODUCT: "PRODUCT",
        ROLE: "ROLE",
        "SOCIAL NETWORK": "SOCIAL NETWORK",
        STYLE: "STYLE",
        TERM: "TERM",
        USER: "USER",
        WISHLIST: "WISHLIST",
        WITHDRAWAL: "WITHDRAWAL",
        APPLICATION: "APPLICATION",
        PENALTY: "PENALTY",
        NONE: "NONE",
      },
      TYPE_OF_PRIVILEGES: {
        C: "C",
        R: "R",
        U: "U",
        D: "D",
      },
      TERM_TYPES: {
        DAYS: "days",
        WEEKS: "weeks",
        MONTHS: "months",
        YEARS: "years",
      },
      DETAIL_TYPES: {
        TEXT: "TEXT",
        COLOR: "COLOR",
        DATE: "DATE",
        FILE: "FILE",
        TERM: "TERM",
      },
      PLATFORM_TYPE: {
        CRYPTO: "CRYPTO",
        TRADITIONAL: "TRADITIONAL",
      },
      NOTIFICATION_TIMEOUT: {
        ERROR: (process.env.VUE_APP_NOTIFICATION_ERROR_TIMEOUT ||
          "6000") as string,
        SUCCESS: (process.env.VUE_APP_NOTIFICATION_TIMEOUT || "3000") as string,
      },
      THEME_COLORS: {
        PRIMARY: "primary",
        SECONDARY: "secondary",
        ACCENT: "accent",
        ERROR: "error",
        INFO: "info",
        SUCCESS: "success",
        WARNING: "warning",
        ANCHOR: "anchor",
      },
      SUPPORT: {
        BASE: "/staff/support/resource",
        TOPIC: {
          SUMMARY_DASHBOARD: "summary-dashboard",
        },
        RESOURCE: {
          STATISTICS: "statistics",
          STORAGE_SPACE: "storage-space",
          OTHERS: "others",
        },
      },
    };
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $constants: {
      COLOR: any;
      STATUS: any;
      FUNCTIONALITY: any;
      FILE_CATEGORY: any;
      OFFER: any;
      PAYMENT_METHOD: any;
      CONTEXT: any;
      USER_TYPE: any;
      EMAILS_SUBJECT: any;
      EMAIL_TEMPLATES: any;
      PAGE: any;
      PRIVILEGES: any;
      TYPE_OF_PRIVILEGES: any;
      TERM_TYPES: any;
      DETAIL_TYPES: any;
      PLATFORM_TYPE: any;
      NOTIFICATION_TIMEOUT: any;
      THEME_COLORS: any;
      SUPPORT: any;
    };
  }
}

export default Constants;
